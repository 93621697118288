import React from 'react';
import { View } from '@aws-amplify/ui-react';
import MenuNav from './Menu';
import Footer from './Footer';
import Apr_21_2022 from './press-release/Apr-21-2022';
import May_17_2022 from './press-release/May_17_2022';
import May_20_2022 from './press-release/May_20_2022';
import May_24_2022 from './press-release/May_24_2022';
import June_17_2022 from './press-release/Jun-17-2022';
import Aug_25_2022 from './press-release/Aug-25-2022';
import Sep_01_2022 from './press-release/Sep_01_2022';
import Sep_08_2022 from './press-release/Sep_08_2022';
import Sep_30_2022 from './press-release/Sep_30_2022';
import Nov_04_2022 from './press-release/Nov_04_2022';
import Nov_29_2022 from './press-release/Nov_29_2022';
import Mar_24_2023 from './press-release/Mar-24-2023';
import Apr_18_2023 from './press-release/Apr-18-2023';
import Jul_10_2023 from './press-release/Jul-10-2023';
import Jul_16_2023 from './press-release/Jul-16-2023';
import Oct_14_2023 from './press-release/Oct_14_2023';
import Feb_15_2024 from './press-release/Feb-15-2024';
import May_13_2024 from './press-release/May-13-2024';
import June_29_2024 from './press-release/June-29-2024';
import Sep_04_2024 from './press-release/Sep-04-2024';
import BannerTemplate from './banner/BannerTemplate';
import Oct_18_2024 from './press-release/Oct-18-2024';
import Nov_03_2024 from './press-release/Nov-03-2024';

function HomePage() {

    const bannerStyle = {
        backgroundImage: "url('../assets/img/FundRaiseFlyer.png')",
        backgroundSize: "350px",
        backgroundRepeat: "repeat-x"
        // backgroundImage: "url('assets/img/planting_seedling.png')",
        // backgroundSize: "1400px",
        // backgroundRepeat: "repeat-x"
    };

    return (
        <main>
            <header>
                <BannerTemplate
                    bannerStyle={bannerStyle}
                    heading="ፈልሲ ሰላምን ደሞክራስን ኤርትራ"
                    subHeading="SEEDLING FOR PEACE AND DEMOCRACY IN ERITREA" />
            </header>
            <View className="container">
                <MenuNav />
                <div className="row text-muted">
                    <div className="col-sm-6 text-center">
                        <h2>እዋናዊ መግለጺታት</h2>
                    </div>
                    <div className="col-sm-6 text-center">
                        <h2>Current Announcements</h2>
                    </div>
                </div>

                {/* <!-- Divider --> */} 
                <hr className="my-4" />
                
                <Nov_03_2024 />
                {/* <!-- Divider --> */}
                <hr className="my-4" />

                <Oct_18_2024 />
                {/* <!-- Divider --> */}
                <hr className="my-4" />

                <Sep_04_2024 />

                {/* <!-- Divider --> */}
                <hr className="my-4" />

                <June_29_2024 />

                {/* <!-- Divider --> */}
                <hr className="my-4" />

                <May_13_2024 />

                {/* <!-- Divider --> */}
                <hr className="my-4" />

                <Feb_15_2024 />

                {/* <!-- Divider --> */}
                <hr className="my-4" />

                <Oct_14_2023 />

                {/* <!-- Divider --> */}
                <hr className="my-4" />

                <Jul_16_2023 />

                {/* <!-- Divider --> */}
                <hr className="my-4" />

                <Jul_10_2023 />

                {/* <!-- Divider --> */}
                <hr className="my-4" />

                <Apr_18_2023 />

                {/* <!-- Divider --> */}
                <hr className="my-4" />

                <Mar_24_2023 />

                {/* <!-- Divider --> */}
                <hr className="my-4" />

                <Nov_29_2022 />

                {/* <!-- Divider --> */}
                <hr className="my-4" />

                <Nov_04_2022 />

                {/* <!-- Divider --> */}
                <hr className="my-4" />

                <Sep_30_2022 />

                {/* <!-- Divider --> */}
                <hr className="my-4" />

                <Sep_08_2022 />

                {/* <!-- Divider --> */}
                <hr className="my-4" />

                <Sep_01_2022 />

                {/* <!-- Divider --> */}
                <hr className="my-4" />

                <Aug_25_2022 />

                {/* <!-- Divider --> */}
                <hr className="my-4" />

                <June_17_2022 />

                {/* <!-- Divider --> */}
                <hr className="my-4" />

                <May_24_2022 />

                {/* <!-- Divider --> */}
                <hr className="my-4" />

                <May_20_2022 />

                {/* <!-- Divider --> */}
                <hr className="my-4" />

                <May_17_2022 />

                {/* <!-- Divider --> */}
                <hr className="my-4" />
                <Apr_21_2022 />
            </View>
            <Footer />
        </main>
    );
}

export default HomePage;
