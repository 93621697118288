import React from 'react';

function BannerTemplate({ bannerStyle, heading, subHeading }) {
    return (
        <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel" data-bs-interval="4000">
            <div className="carousel-inner">
                <div className="carousel-item active text-center">
                    <header className="masthead" style={bannerStyle}>
                        <div className="container position-relative px-4 px-lg-5">
                            <div className="row gx-4 gx-lg-5 justify-content-center">
                                <div className="col-md-10 col-lg-8 col-xl-7">
                                    <div className="site-heading">
                                        <h2>{heading}</h2> 
                                        <br />
                                        <span className="subheading"><a href="/support"><strong style={{color:'white'}}> ወፍዩ | Donate </strong></a></span>
                                        <br />
                                        <span className="subheading"><a target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSfm7XAsY2stsmPUqHCyIfj0D6nzgWz6XwmxYUBUkntpcq2WVg/viewform?usp=sharing"><strong style={{color:'white'}}> ቅጥዒ መብጽዓ | Pledge From </strong></a></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>
                </div>
            </div>
            {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
            </button>
            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
            </button> */}
        </div>

    );
}

export default BannerTemplate;
